<template>
  <div class="newfox">
   <Row type="flex" justify="space-around" class="code-row-bg">
                <Col span="6" push="7" class-name="zixing" @click.native="shouye()">首页</Col>
                <Col span="6" push="2"   class-name="zixingz">>新闻中心</Col>
                <Col span="6"  class-name="zi">>{{typename}}</Col>
   </Row>
    <Row type="flex" class-name="breadcrumbs_main_main">
      <Col push="5" class-name="breadcrumbs">
        <div class="breadcrumbs_title">
         <img class="noe-tu" src="../../assets/img/xinx/分类@2x.png" alt="">
          <span class="breadcrumbs_title_mai">列表导航</span>
        </div>
        <div  class="breadcrumbs_title_trends" :style="{ background: num == 1 ? 'rgba(45, 140, 240, 1)' : '#fff', color: num == 1 ? '#ffffff' : 'rgba(0, 92, 176, 1)',}" @click="clack()"  >
          <img class="breadcrumbs_title_img2" src="../../assets/img/xinx/中心动态蓝色@2x.png"  alt="" ref="zx1" :style="{display:num==1?'none':'block'}" />
          <img class="breadcrumbs_title_img3" src="../../assets/img/xinx/中心动态@2x.png"  alt="" ref="zx2" :style="{display:num==1?'block':'none'}"/>
          <span class="breadcrumbs_title_main">工作动态</span>
        </div>
        <div class="breadcrumbs_title_about" :style="{ background: num == 2 ? 'rgba(45, 140, 240, 1)' : '#fff', color: num == 2 ? '#ffffff' : 'rgba(0, 92, 176, 1)',  }" @click="clack1()" >
        <img class="breadcrumbs_title_img2" src="../../assets/img/xinx/新闻@2x.png" alt="" ref="zx3" :style="{display:num==2?'none':'block'}">
         <img class="breadcrumbs_title_img3" src="../../assets/img/xinx/新闻白色@2x.png" alt="" ref="zx4" :style="{display:num== 2 ? 'block':'none'}">
          <span class="breadcrumbs_title_main">业内新闻</span>
        </div>
      </Col>
      <Col push="6" span="10" v-show="num == 1" class-name="breadcrumbs_main">
        <div class="breadcrumbs_main_txt_title">
          <img
            class="breadcrumbs_main_txt_title_img"
            src="../../assets/img/news/Combined Shape.png"
            alt=""
          />
          <span class="breadcrumbs_txt_title__txt">工作动态</span>
        </div>
        <div class="hengxian"></div>
        <Row type="flex" v-for="(item,index) in zhongxinews" :key=index>
          <Col span="19" class-name="msg_title"   >
               <div @click="leix(item.newsMode,item.id)">{{item.newsTitle}}</div>
          </Col>
            <Col push="2" span="5" class-name="msg_title">{{item.createTime }}</Col>
        </Row>
        <!-- 分页 -->
                  <el-pagination class="fenye"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[15]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount">
                   </el-pagination>
      </Col>
      <Col push="6" span="10" v-show="num == 2" class-name="breadcrumbs_main">
        <div class="breadcrumbs_main_txt_title">
          <img  class="breadcrumbs_main_txt_title_img"  src="../../assets/img/news/Combined Shape.png"  alt=""/>
          <span class="breadcrumbs_txt_title__txt">业内新闻</span>
        </div>
         <div class="hengxian"></div>
        <Row type="flex" v-for="(item,index) in yeyunew" :key=index>
          <Col span="19" class-name="msg_title" >
                <div @click="yenei(item.newsMode,item.id)"> {{item.newsTitle}}</div>
          </Col>
          <Col push="2" span="5" class-name="msg_title">{{item.createTime }}</Col>
        </Row>
        <el-pagination class="fenyex"
                        @size-change="SizeChange"
                        @current-change="CurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[15]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="yeyus">
                    </el-pagination>
      </Col>
    </Row>
   
  </div>
</template>
<script>
import {incrReadingNum,queryPageNews} from "../../woke/xinx"
 import { formatDate } from '../../commm/time'
export default {
  data() {
    return {
      num: 2,
      zhongxinews:[],
      yeyunew:[],
     
      totalCount:null,//总数据
       currentPage:1,//初始页
        pageSize: 15 ,//每页显示条目数
        yeyus:null,//总数据
      //是否置顶1是2否3全部
      isStick:"",
      //每页最大条数必填
     
      //新闻类型1中心动态2业内新闻3通知公告4政策法规
      type:"",
      //新闻标题
      newsTitle:"",
      //发布时间
      pushTime:"",
      typename:""

    };
  },
  created(){
      this.queryPageNews()
      this.queryPageNewsyeyu()
       if(this.num == 1){
        this.typename = "工作动态"
      } else{
        this.typename = "业内新闻"
      }
  },
  methods: {
    clack(){
      this.num = 1
      if(this.num == 1){
        this.typename = "工作动态"
      } else{
        this.typename = "业内新闻"
      }
    },
    clack1(){
      this.num = 2
      if(this.num == 1){
        this.typename = "工作动态"
      } else{
        this.typename = "业内新闻"
      }
    },
       //改变分页的每页的页数
     handleSizeChange(size) {
       this.pageSize = size
        this.queryPageNews()
     },
     // 改变分页的当前页面
     handleCurrentChange(currentPage) {
       this.currentPage = currentPage
        this.queryPageNews()
     },
      leix(newsMode,id) {
      console.log(id);
            incrReadingNum({
                id:id,
                type:1
            }).then(res=>{
                console.log(res);
            })
      if (newsMode == 1) {
        // console.log(11);
        this.$router.push({
          name: "newsdetail",
          params: {
            id: id
          }
        });
      } else if (newsMode == 2) {
        window.open(content, "_blank")
      }
    },
    yenei(newsMode,id) {
      console.log(id);
            incrReadingNum({
                id:id,
                type:1
            }).then(res=>{
                console.log(res);
            })
      if (newsMode == 1) {
        // console.log(11);
        this.$router.push({
          name: "yenei",
          params: {
            id:id
          }
        });
      } else if (newsMode == 2) {
        window.open(content, "_blank")
      }
    },
    shouye(){
        this.$router.push("/index");
    },
    //中心新闻
    queryPageNews(){
        queryPageNews({
             curPage:this.currentPage,
            maxPage:this.pageSize,
            isStick:3,
            type:1
        }).then(res=>{
            // console.log(res.data.data,"中心新闻");
            this.zhongxinews=res.data.data.data
             this.totalCount =  res.data.data.total;
        })
    },
    //业余
    queryPageNewsyeyu(){
        queryPageNews({
             curPage:this.currentPage,
            maxPage:this.pageSize,
            isStick:3,
            type:2
        }).then(res=>{
            // console.log(res,"业余");
            this.yeyunew=res.data.data.data
             this.yeyus = res.data.data.total
        })
    },
      SizeChange(size){
         this.pageSize = size;
         this.queryPageNewsyeyu()
    },
    CurrentChange(currentPage){
        //   console.log(currentPage);
       this.currentPage = currentPage;
       this.queryPageNewsyeyu()
    }
  },
  filters:{
       /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/ 
    formatDate(time) {
        var date = new Date(time);
        return formatDate(date, 'yyyy-MM-dd'); 
    },
    },
    
};
</script>
<style scoped>
.newfox{
    margin-top: 60px;
}
.laypage {
  height: 50px;
  justify-content: center;
}
.msg_title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #2c3b5b;
  align-items: center;
}
.news_nav {
    width: 100px;
  height: 50px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #4d4d4d;
  line-height: 50px;
  cursor: pointer;
}
.code-row-bg{
    width: 350px;
    margin-left: 295px;
    margin-bottom: 20px;
    margin-top: 30px;
}
.noe-tu{
   width: 17px;
   height: 17px;
   margin-top: 3px;
   margin-left: 5px;
}
.zixing{
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
    cursor: pointer;
}
.zixingz{
     margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
}
.zi{
     margin-top: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #304C87;
    line-height: 21px;
}
.breadcrumbs_two_nav {
  width: 202px;
  height: 500px;
}
.breadcrumbs {
  width: 202px;
  height: 184px;
  border: 1px #005cb0 solid;
  margin-left: 15px;
}
.breadcrumbs_title {
  width: 200px;
  height: 60px;
  background: #005cb0;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.breadcrumbs_title_img {
  width: 15px;
  height: 15px;
}
.breadcrumbs_title_img2 {
  width: 14px;
  height: 13px;
}
.breadcrumbs_title_img3{
     width: 14px;
  height: 13px;
}
.breadcrumbs_title_mai{
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
    margin-left: 11px;
}
.breadcrumbs_title_main {
   font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    line-height: 24px;
    margin-left: 11px;
}
.breadcrumbs_title_trends {
  width: 200px;
  height: 60px;
  background: rgba(45, 140, 240, 1);
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.breadcrumbs_title_about {
  width: 200px;
  height: 62px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: rgba(0, 92, 176, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.breadcrumbs_main {
  width: 200px;
  height: 700px;
  margin-left: 40px;
}
.breadcrumbs_main_txt_title {
  width: 800px;
  height: 30px;
  border-bottom: rgba(0, 92, 176);
  display: flex;
  align-items: center;
   margin-top: -3px;
}
.breadcrumbs_main_txt_title_img {
  width: 10px;
  height: 16px;
}
.breadcrumbs_txt_title__txt {
  margin-left: 10px;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #2c3b5b;
  
}
.msg_title{
    
    margin-bottom: 10px;
}
.hengxian{
     margin-top: 15px;
     width:855px;
     height:1px;
     background-color:#005CB0;
     overflow:hidden;
     margin-bottom: 15px;
    /* margin-left: 100px; */
}
.fenye{
     width: 600px;
     margin-top: 50px;
     margin-left: 408px;
 }
 .fenyex{
     width: 600px;
     margin-top: 50px;
     margin-left: 418px;
 }
</style>

